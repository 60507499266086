import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SmallButton } from "../components/buttons"
export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout>
        <SEO title="Blog" />
        <div className="px-2 md:px-8">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 flex m-auto">
              <div className="w-full flex flex-wrap">
                <div className="w-full">
                  <div className="bg-blue-700 px-4 py-2 shadow-md text-white rounded-lg mb-8 md:mx-4 md:text-4xl lg:py-2 lg:px-4 leading-normal">
                    <div className="text-2xl my-1 font-semibold">Blog</div>
                    <p className="font-thin text-base my-1">
                      This is the Arctium Blog. You'll find important
                      announcements as well as informative entry's here in the
                      future.
                    </p>
                  </div>
                </div>

                {posts.map(({ node }) => {
                  const title = node.frontmatter.title || node.frontmatter.slug
                  const excerpt = node.excerpt
                  return (
                    <div className="w-full xl:w-1/2 md:px-4 pb-3 mb-3">
                      <div
                        className="card my-3 p-5 leading-none"
                        key={node.frontmatter.slug}
                      >
                        <div className="font-bold mb-2 text-3xl">
                          <Link to={node.frontmatter.slug}>{title}</Link>
                        </div>
                        <div className="text-xs leading-5 font-thin">
                          Published on{" "}
                          <span className="font-semibold">
                            {node.frontmatter.date}
                          </span>{" "}
                          by{" "}
                          <span className="font-semibold">
                            {node.frontmatter.author}
                          </span>
                        </div>
                        <p className="font-light text-sm my-3">{excerpt}</p>
                        <Link className="" to={node.frontmatter.slug}>
                          <SmallButton>Read More</SmallButton>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "article" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(format: PLAIN)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            author
          }
        }
      }
    }
  }
`
